<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-on="on"
        :value="dateFormated"
        :label="initLabel"
        :rules="rule ? [] : [v => !!v || $t('app.required')]"
        :disabled="disabled"
        :outlined="outlined"
        :dense="dense"
        :hide-details="hideDetails"
        clearable
        append-icon="mdi-calendar"
        readonly
        @click:clear="date = null"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      :min="minDate"
      :max="maxDate"
      :no-title="notitle"
      :locale="lang"
      @input="menu = false"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

export default {
  name: "Datepicker",
  props: {
    value: String,
    label: String,
    rule: [Boolean, Array],
    minDate: String,
    maxDate: String,
    disabled: Boolean,
    outlined: Boolean,
    dense: Boolean,
    notitle: Boolean,
    hideDetails: Boolean
  },
  computed: {
    dateFormated() {
      return this.date ? moment(this.date).format("DD MMM YYYY") : null;
    },
    lang() {
      return this.$store.getters.g_language;
    }
  },
  watch: {
    date(newVal) {
      this.$emit("getValue", newVal);
    },
    value(newVal) {
      this.date = newVal;
    }
  },
  mounted() {
    this.date = "";
    this.$nextTick(() => {
      this.date = this.value;
    });
  },
  data() {
    return {
      initLabel: this.label || i18n.t("components.choose_date"),
      menu: false,
      date: null,
      dateSelected: null
    };
  }
};
</script>
